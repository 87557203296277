<template>
  <div class="app-container">
    <searchHead
      v-p="['externaladmin:system:vip:search']"
      :sup_this="sup_this"
      :query="query"
      @search="search"
      style="margin-bottom: 30px"
    ></searchHead>
    <PaginationBar :page="page" :size="size" :total="total" @refreshTableEventFun="refreshTableEventFun" />
    <CommonTable :selection="false" :tableLoading="tableLoading" :cols="cols" :infoData="data">
      <template #levelNumberSlot="{ scoped: row }">
        <color-text-btn @click="handleClicked(row)">{{ row.memberCount }} </color-text-btn>
      </template>
      <template #goodsRateSlot="{ scoped: row }"> {{ getPriceDiscount(row) }}</template>
      <template #stateSlot="{ scoped: row }"> {{ getState(row) }} </template>
      <template #createTimeSlot="{ scoped: row }"> {{ parseTime(row[row.prop]) || '暂无' }} </template>
      <el-table-column fixed="right" label="操作" width="300px" align="center">
        <template slot-scope="{ row, $index }">
          <div v-if="row.isNormal === 1">
            <color-text-btn @click="onEdit(row)" v-p="['externaladmin:system:vip:edit']">编辑</color-text-btn>
          </div>
          <div class="flex-middle-center" v-else-if="row.isEnabled == 1">
            <color-text-btn @click="onEdit(row)" v-p="['externaladmin:system:vip:edit']">编辑</color-text-btn>
            <el-divider direction="vertical"></el-divider>
            <el-popover placement="top" :ref="`popover-${$index}-disable`" width="200" trigger="click">
              <slot name="tip">
                <p style="font-weight: 500; font-size: 16px">提示</p>
                <p>禁用后对应会员折扣会消失，您确定要禁用吗？</p>
              </slot>
              <div style="text-align: right">
                <el-button size="mini" type="text" @click="onCancel('disable', $index, row.id)">取消</el-button>
                <el-button type="primary" :loading="loading" size="mini" @click="onConfirm('disable', $index, row.id)"
                  >确定</el-button
                >
              </div>
              <color-text-btn
                slot="reference"
                v-p="['externaladmin:system:vip:disable']"
                :loading="loadingList[$index]"
                type="danger"
                >禁用</color-text-btn
              >
            </el-popover>
          </div>
          <div v-else>
            <el-popover placement="top" :ref="`popover-${$index}`" width="200" trigger="click">
              <slot name="tip">
                <div style="font-weight: 500; font-size: 16px">提示</div>
                <p>重新启用后需要设置会员信息</p>
              </slot>
              <div style="text-align: right">
                <el-button size="mini" type="text" @click="onCancel('open', $index, row.id)">取消</el-button>
                <el-button type="primary" :loading="loading" size="mini" @click="onConfirm('open', $index, row.id)"
                  >确定</el-button
                >
              </div>
              <color-text-btn slot="reference" v-p="['externaladmin:system:vip:disable']" :loading="loadingList[$index]"
                >重新启用</color-text-btn
              >
            </el-popover>
          </div>
        </template>
      </el-table-column>
    </CommonTable>
    <editDialog
      @refreshPage="refreshPage"
      :modifiedID="modifiedID"
      :type="type"
      :ruleForm.sync="ruleForm"
      :visible.sync="dialogVisible"
    />
    <numberDialog
      v-if="visible"
      @refreshPage="refreshPage"
      :vipData="data"
      :levelID="levelID"
      :visible.sync="visible"
      :curLevel="userLevel"
    />
    <PaginationBar :page="page" :size="size" :total="total" @refreshTableEventFun="refreshTableEventFun" />
  </div>
</template>

<script>
import searchHead from './module/header.vue'
import editDialog from './module/addOrEditDialog.vue'
import numberDialog from './module/levelNumberDialog.vue'
import initDataMixin from '@/mixins/initData'
import { vipLevelCols as cols } from './module/cols'
import { parseTime } from '@/utils'
import { changeVipState } from '@/api/vipLevel'

export default {
  components: { searchHead, editDialog, numberDialog },
  mixins: [initDataMixin],
  data() {
    return {
      cols,
      dialogVisible: false,
      visible: false,
      sup_this: this,
      reOpenTip: false,
      levelID: 0,
      url: '/externaladmin/financeService/memberLevelList',
      userLevel: '',
      ruleForm: {
        levelID: '',
        roleID: [],
        levelName: '',
        logo: ''
      },
      loading: false,
      query: {
        levelName: '',
        isEnabled: '',
        createTime: ''
      },
      modifiedID: 0,
      type: '',
      loadingList: {}
    }
  },
  methods: {
    parseTime,
    async onEdit(data) {
      //分别解构，赋值给ruleForm的各属性
      this.type = 'edit'
      const { level, levelName, roleId, isNormal } = data
      this.ruleForm.levelID = level
      this.ruleForm.levelName = levelName
      this.ruleForm.roleID = roleId
      this.ruleForm.isNormal = isNormal === 1
      this.modifiedID = data.id
      this.dialogVisible = true
    },
    refreshPage() {
      this.init()
    },

    search() {
      if (typeof this.query.createTime == 'object') {
        if (this.query.createTime) {
          this.query.addStartTime = this.query.createTime[0] + ' 00:00:00'
          this.query.addEndTime = this.query.createTime[1] + ' 23:59:59'
          delete this.query.createTime
        } else {
          this.query.addStartTime = ''
          this.query.addEndTime = ''
        }
      }
      this.searchChange()
    },
    handleClicked(data) {
      this.levelID = data.id
      this.userLevel = data.level
      this.visible = true
    },
    onCancel(type, index) {
      if (type === 'disable') {
        this.$refs[`popover-${index}-disable`].doClose()
      } else {
        this.$refs[`popover-${index}`].doClose()
      }
    },
    initLoadingBtn(index) {
      this.loading = false
      this.loadingList[index] = false
    },
    async onConfirm(type, index, id) {
      try {
        this.loading = true
        this.loadingList[index] = true
        const message = type === 'disable' ? '禁用成功' : '启用成功，请尽快设置会员信息哦'
        const { code } = await changeVipState({ id })
        if (code != 0) return
        if (type === 'disable') this.$refs[`popover-${index}-disable`].doClose()
        else this.$refs[`popover-${index}`].doClose()
        this.initLoadingBtn(index)
        this.init()
        this.$message.success(`${message}`)
      } catch (error) {
        this.initLoadingBtn(index)
      }
    }
  },
  computed: {
    getState() {
      return (data) => {
        const { isEnabled } = data
        const results = isEnabled == '1' ? '已启用' : '已禁用'
        return results
      }
    },
    getPriceDiscount() {
      return (row) => {
        return (row.priceDiscount * 100) % 1 > 0.5
          ? Math.ceil(row.priceDiscount * 100) + '%'
          : Math.floor(row.priceDiscount * 100) + '%'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.app-container {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  padding: 20px 30px;
}
</style>
