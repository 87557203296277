<template>
  <div>
    <el-dialog
      append-to-body
      title="查看人数"
      :visible.sync="visible"
      width="800px"
      :before-close="handleClose"
      class="dialog-warpper"
    >
      <el-button type="primary" style="padding: 8px; height: 30px; margin-bottom: 20px" @click="handleBatchModified"
        >批量修改等级</el-button
      >
      <CommonTable
        height="500px"
        :cols="cols"
        :tableLoading="tableLoading"
        @selection-change="handleSelectionChange"
        :infoData="data"
      >
        <el-table-column label="操作" width="270">
          <template slot-scope="{ row }">
            <color-text-btn @click="handleModified(row)">修改等级</color-text-btn>
          </template>
        </el-table-column>
      </CommonTable>
      <PaginationBar ref="paginationBar" @refreshTableEventFun="refreshTableEventFun" :total="total" />
    </el-dialog>
    <modifyLevel
      v-if="show"
      @refreshPage="refreshPage"
      :vipList="vipData"
      :userLevel="userLevel"
      :sup_this="sup_this"
      :type="type"
      :selectIdList="selectIdList"
      :visible.sync="show"
      v-bind="$attrs"
    />
  </div>
</template>

<script>
import modifyLevel from './modifyLevel.vue'
import initDataMixin from '@/mixins/initData'
import { cols } from './cols'
import { changeVipLevel } from '@/api/vipLevel'

export default {
  mixins: [initDataMixin],
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    levelID: {
      type: Number,
      default: 0
    },
    vipData: {
      type: Array,
      default: () => []
    }
  },
  watch: {},
  components: { modifyLevel },
  data() {
    return {
      cols,
      url: '/externaladmin/financeService/memberLevel/levelCount',
      xMenuType: 'other',
      mergeData: {
        id: this.levelID
      },
      sup_this: this,
      multipleSelection: [],
      show: false,
      selectIdList: [],
      type: 'single',
      userLevel: 0
    }
  },
  methods: {
    handleModified(data) {
      this.type = 'single'
      const { id, userLevel } = data
      this.selectIdList = [id]
      this.userLevel = userLevel
      this.show = true
    },
    refreshPage() {
      this.$emit('refreshPage')
    },
    handleBatchModified() {
      this.type = 'batch'
      if (!this.multipleSelection.length) {
        this.$message.warning('请先选择分销商再批量修改等级')
        return
      }
      this.selectIdList = this.multipleSelection.map((item) => item.id)
      this.show = true
    },
    handleSelectionChange(val) {
      this.multipleSelection = val
    },
    handleClose() {
      this.$emit('update:visible', false)
    },
    handleCanceled() {},
    handleConfirmed() {}
  },
  computed: {
    levelIDList() {
      return this.vipData.map(({ id, level }) => ({
        value: id,
        label: level
      }))
    }
  },
  created() {
    console.log(1)
  }
}
</script>

<style lang="scss" scoped>
.dialog-warpper {
  ::v-deep {
    .el-dialog__title {
      font-size: 14px;
      color: #1a1a1a;
    }
    .el-dialog__header {
      height: 52px;
      line-height: 52px;
      padding: 0 0 0 24px;
      border: 1px solid #dcdee0;
    }
  }
}
</style>
